@layer components
	.block-title,.zone-title
		@apply font-semibold leading-tight mb-7 text-clamp-40 text-primary-700
		*
			@apply font-semibold leading-tight text-clamp-40 text-primary-700
		@screen xl
			@apply text-clamp-48 mb-9
			*
				@apply text-clamp-48 
	.zone-title
		@apply text-clamp-48
		*
			@apply text-clamp-48
		@screen xl
			@apply text-clamp-64
			*
				@apply text-clamp-64
	.headline
		@apply leading-tight text-clamp-20 text-primary-700 font-bold
		*
			@apply leading-tight  text-clamp-20 text-primary-700 font-bold
	.sub-title
		@apply leading-tight text-clamp-18 text-green-400 font-bold
		*
			@apply leading-tight  text-clamp-18 text-green-400 font-bold
	.head-title
		@apply text-grey-950 text-clamp-18 font-normal transition leading-tight block
		*
			@apply text-grey-950 text-clamp-18 font-normal transition leading-tight 
		&:hover
				@apply text-primary-700
				*
					@apply text-primary-700
	.block-title,.zone-title,.headline,.sub-title
		@apply transition
		*
			@apply transition