.primary-banner
	@apply relative overflow-hidden
	.banner-container
		@apply relative
		@media screen and ( max-width: 768px)
			position: relative
			display: block
			height: 0
			overflow: hidden 
			padding-top: 9/16 *100%
			.swiper
				position: absolute
				width: 100%
				height: 100%
				top: 0
				left: 0
	.wrap
		@apply relative
		.container-fluid
			@apply absolute-center text-left z-30
	.img
		@apply relative
		@media screen and ( min-width: 768.1px)
			&::after
				content: ''
				@apply block absolute pointer-events-none left-0 bottom-0 z-10  transition w-full h-full
				background: linear-gradient(90deg, rgba(#00475E,.2 ) 0%, rgba(0, 101, 133, 0) 100%)
			a
				+img-ratio(960/1920)
		@media screen and ( max-width: 768px)
			a
		
				+img-ratio(9/16)
		// .desktop
		// 	@apply hidden
		// 	@screen md
		// 		@apply block
		// .mobile
		// 	@apply hidden
		// 	@media screen and ( max-width: 768px)
		// 		@apply block
	.swiper-slide
		@screen md
			&.swiper-slide-active
				.title,.desc,.wrap-left
					@apply opacity-0
					animation: fade-in 1s cubic-bezier(0.39, 0.575, 0.565, 1) .4s both
				.desc
					animation-delay: .6s
				.wrap-left
					animation-delay: .8s
	.wrap-left
		@media screen and ( max-width: 1024px)
			@apply justify-center
		@media screen and ( max-width: 576px)
			@apply hidden
	.txt
		@apply text-center
		@screen lg
			@apply w-1/2 mr-auto text-left
	.block-title
		@apply mb-3
		@media screen and ( max-width: 576px)
			@apply text-clamp-24
			*
				@apply text-clamp-24
	.desc
		@media screen and ( max-width: 576px)
			@apply text-clamp-14
			+line(3)
	.swiper-pagination
		bottom: r(50px) !important
		@apply  z-40 absolute-x center-item gap-3
		left: 50% !important
		transform: translateX(-50%)
		@media screen and ( max-width: 1400px)
			bottom: r(30px) !important
		@media screen and ( max-width: 1024px)
			@apply justify-center
			bottom: r(20px) !important
	.swiper-pagination-bullet
		@apply  rounded-full transition opacity-100 relative overflow-hidden  bg-transparent border-2 border-primary-900
		margin: 0 !important
		transform: scale(1)
		height: 16px
		width: 16px
		max-height: 16px !important
		min-width: 16px !important
		@media screen and ( max-width: 1200px)
			margin: 0 4px !important
		&.swiper-pagination-bullet-active
			@apply opacity-100  block bg-white
			.pagination-title
				@apply opacity-100