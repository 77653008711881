.home-factory
	.container-fluid
		@screen lg
			@apply absolute-center z-30
	.txt
		@media screen and ( max-width: 1024px)
			@apply pt-15 text-center
	.wrap-left
		@media screen and ( max-width: 1024px)
			@apply justify-center
	.img
		img
			aspect-ratio: 1920/775