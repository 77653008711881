//Type 1 first child icon
.modulepager,.post-nav
	@apply flex items-center justify-center pt-8 pb-0
	ul
		padding: 0
		margin: 0
		position: relative
		li
			display: inline-block
			padding: 0 10px
			&.active
				a,span
					@apply text-green-400 border-green-400 bg-white
			&:hover
				a,span
					@apply text-green-400  border-green-400 bg-white
			
			span,a
				transition: .3s all  ease-in-out
				display: flex
				justify-content: center
				align-items: center
				width: 40px
				height: 40px
				text-decoration: none
				@apply text-primary-700 text-clamp-18 bg-primary-100 font-bold rounded-full overflow-hidden border border-transparent
		.next
			a
				width: max-content
				@apply whitespace-nowrap
					
