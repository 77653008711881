.home-achivement
	.icon
		@apply start-item
		height: r(90px)
		width: auto
		@screen sm
			height: r(160px)
		@screen md
			height: r(160px)
		@screen xl
			height: r(210px)
		img
			width: auto
			height: 100%
			object-fit: contain
	.item
		.block-title
			margin: r(32px) 0 r(16px)
			@media screen and ( max-width: 576px)
				@apply text-clamp-32
	.two-swiper
		.swiper-slide
			@apply h-auto
			@media screen and ( max-width: 576px)
				@apply w-full
		.item 
			@apply h-full