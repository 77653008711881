.home-about
	padding: r(70px) 0 
	@screen xl
		padding: r(120px) 0 r(70px)
	&::before,&::after
		content: ''
		@apply block absolute pointer-events-none z-10 
		background-repeat: no-repeat !important
		background-size: contain !important
		background-position: center !important
	&::before
		background: url('../img/overlay/home-1.png')
		width: r(146px)
		height: r(104px)
		@apply top-0 left-0
	&::after
		background: url('../img/overlay/home-2.png')
		width: r(260px)
		height: r(345px)
		@apply bottom-0 right-0
		@media screen and ( max-width: 1024px)
			width: r(160px)
			height: r(245px)
	.zone-title
		@apply mb-4
	.img 
		a
			@apply end-item
		.desktop
			@apply hidden
			@screen md
				@apply block
		.mobile
			@apply hidden
			@media screen and ( max-width: 768px)
				@apply block
	.item
		@apply relative rounded-b-0
		&::after
			content: ''
			@apply block absolute pointer-events-none left-0 bottom-0 z-10  transition w-full h-[4px]
			background: radial-gradient(100% 1600% at 0% 0%, #00A8DF 0%, #FFFFFF 100%) 
	.counter
		@media screen and ( max-width: 1400px)
			@apply leading-none
	.chart-wrap
		@media screen and ( min-width: 1024px) and ( max-width: 1280px)
			grid-template-columns: repeat(2, minmax(0, 1fr))