.btn
	span,em
		transition: .4s all  ease-in-out
	&.btn-primary
		@apply  px-6 rounded bg-primary-700 border border-primary-700
		height: r(48px)
		min-height: 40px
		&::after
			content: ''
			@apply block absolute pointer-events-none left-0 bottom-0 z-10  transition w-0 h-full bg-white
		&:hover
			@apply rounded-0
			&::after
				@apply w-full
			span,em
				@apply text-primary-700
		em
			@apply ml-3 text-clamp-12 leading-none start-item
		span,em
			@apply text-clamp-16 relative z-30 text-white
		&.white
			@apply bg-white border border-primary-700
			span,em
				@apply text-primary-700
				&::before
					@apply text-primary-700
			&:hover
				@apply border-transparent
				span,em
					@apply text-white
					&::before
						@apply text-white
			&::after
				@apply bg-primary-700
	&.btn-secondary
		@apply pr-4
		span,em
			@apply font-bold
		span
			@apply  uppercase
		em
			@apply ml-3
		&:hover
			span,em
			em
				transform: translateX(3px)
	&.btn-tertiary
		@apply pr-1
		span,em
			@apply text-white leading-none text-clamp-16 transition
		em
			@apply ml-3
		&:hover
			em
				transform: translateX(3px)
		&.blue
			span,em
				@apply text-primary-700
	&.btn-back
		@apply  px-6 rounded bg-grey-100
		height: r(48px)
		min-height: 40px
		span
			@apply text-clamp-16 text-grey-500
		&:hover
			@apply bg-red
			span
				@apply text-white
	&.btn-download
		em
			@apply text-clamp-32 font-black text-green-400
.prev,.next
	@apply rounded-2 border border-primary-700  bg-white center-item  transition
	height: r(60px)
	width: r(60px)
	min-width: r(60px)
	@media screen and ( max-width: 1024px)
		@apply mx-3 relative
	@screen lg
		@apply absolute-y relative z-30 
	&:hover
		@apply bg-primary-700
		em
			@apply text-white
		&::after
			@apply w-full
	em
		@apply leading-none relative z-50 transition text-primary-700 text-clamp-32
	&.swiper-button-disabled
		@apply opacity-0 pointer-events-none
.swiper-nav
	@media screen and ( max-width: 1024px)
		@apply center-item pt-6 pb-1
	&.normal
		padding: 0 !important
		margin: 0 !important
		.next,.prev
			left: unset !important
			right: unset !important
			position: relative !important
			transform: none !important
		.prev
			margin-right: 20px
	&.no-bg
		.next,.prev
			@apply bg-transparent
	&.inset
		.next
			right: 0
		.prev
			left: 0
	&.white
		.next,.prev
			@apply border-white
			em
				@apply text-white
	&.second
		.prev,.next
			@apply border-transparent rounded-full
			&::after
				@apply w-full opacity-0
			&:hover
				&::after
					@apply opacity-100
				em
					@apply text-white
	.next

		&::after
			@apply left-0
		@media screen and ( min-width: 1440.1px)
			right: -90px
		@media screen and ( min-width: 1024px) and ( max-width: 1440px)
			right: -60px
	.prev
		&::after
			@apply right-0
		@media screen and ( min-width: 1440.1px)
			left: -90px
		@media screen  and ( min-width: 1024px) and ( max-width: 1440px)
			left: -60px
.wrap-gap,.wrap-center,.wrap-left,.wrap-right,.ajax-center-wrap
	@apply flex items-center
	a
		&:not(:last-child)
			@apply mr-2
.wrap-center,.ajax-center-wrap
	@apply  justify-center
.wrap-left
	@apply justify-start
.wrap-right
	@apply justify-end
.wrap-gap
	@apply justify-between
