.home-product
	padding-top: r(60px)
	@screen xl
		padding-top: r(100px)
	.swiper-slide
		@apply h-auto
	.img
		a
			@apply center-item p-8
			height: r(120px)
			width: auto
			img
				width: auto
				height: 100%
				object-fit: contain
	.six-swiper
		.swiper
			@apply -m-3 p-3 -mx-5 px-2
		.swiper-slide
			padding: 0 10px
	.home-zone-item
		@apply h-full
		background:  var(--color-code)
		.txt
			@apply pb-4 px-4
		.headline
			@apply center-item text-center
			*
				@apply text-white