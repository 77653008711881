html,body
	color: #292929
	font-weight: 400
	font-family: 'Open Sans', sans-serif
	font-size: clamp(14px,3.6vw,16px)
	@screen sm
		font-size: 2.4vw
	@screen md
		font-size: 1.5vw
	@screen lg
		font-size: 1.1vw
	@screen xl
		font-size: 1vw
*
	&:focus
		outline: none
a
	color: inherit
	text-decoration: none
p
	margin-bottom: 10px
	font-weight: 400
	color: inherit
	font-size: inherit
figure
	p
		font-weight: 300
		font-size: 1rem
time
	@apply font-normal  inline-block text-grey-500 text-clamp-14 
html
	line-height: 1.2
	-webkit-text-size-adjust: 100%
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0)
article, aside, figcaption, figure, footer, header, hgroup, main, nav, section
	display: block
body
	margin: 0
	font-weight: 400
	line-height: 1.2
	color: #333333
	text-align: left
	background-color: #ffffff
hr
	box-sizing: content-box
	height: 0
	overflow: visible

.frm-btn-reset
	display: none !important
.col-12
	@apply w-full
	-webkit-box-flex: 0
	-ms-flex: 0 0 auto
	-webkit-box-orient: vertical
	-webkit-box-direction: normal
	-ms-flex-direction: column
	flex: 0 0 auto
	flex-direction: column
	padding: 0 15px
.col-lg-6
	width: 100%
	padding: 0 15px
	@screen lg
		width: 50%
.col-lg-8
	padding: 0 15px
	@screen lg
		width: 66.666667%
.col-lg-4
	padding: 0 15px
	@screen lg
		width: 33.333333%
.col-xs-12
	padding: 0 15px
	width: 100%
.col-lg-3
	padding: 0 15px
	@screen lg
		width: 25%
.col-lg-9
	padding: 0 15px
	@screen lg
		width: 75%
@function r($size)
	@return ($size/1920px*100rem)
=clamp($min,$max, $line-height, $lines-to-show)
	@if unitless($line-height) == false 
		$line-height: create-unitless-line-height($font-size, $line-height)
	display: -webkit-box
	font-size: clamp($min,r($max),$max)
	height: (r($max)* $line-height * $lines-to-show) 
	min-height: ($min* $line-height * $lines-to-show) 
	line-height: $line-height
	overflow: hidden
	text-overflow: ellipsis
	-webkit-box-orient: vertical
	-webkit-line-clamp: $lines-to-show
=fz($min,$max)
	font-size: clamp($min,r($max),$max)
=line($line)
	display: -webkit-box
	-webkit-line-clamp:  $line
	-webkit-box-orient: vertical  
	overflow: hidden
=res-nav
	@apply overflow-x-auto
	ul
		@apply start-item whitespace-nowrap pb-1
		justify-content: flex-start
		li
			&:first-child
				@apply pl-0