.top-banner
	@apply relative overflow-hidden
	a
		@apply relative
		&::after
			content: ''
			@apply block absolute-center pointer-events-none z-10  transition w-full h-full
			background: linear-gradient(180deg, rgba(0, 0, 0, 0) 48.19%, rgba(0, 0, 0, 0.748) 100%)
		@media screen and ( max-width: 1024px)
			+img-ratio(9,16)
		@screen lg
			+img-ratio(650,1920)