
.global-breadcrumb
	@apply  flex justify-start items-center 
	min-height: 50px
	.container
		@apply h-full relative flex items-center py-1
		min-height: 50px
		&::after
			content: ''
			@apply block absolute-x pointer-events-none  bottom-0 z-10  transition  h-[1px] bg-grey-200
			width: calc( 100% - 30px )
	ol
		@apply p-0 list-none
		li
			@apply inline-flex relative leading-none
			&:not(:last-child)
				@apply mr-8
			&:after
				content: ''
				@apply  block font-light absolute-y pointer-events-none z-10 bg-grey-500
				right: r(-20px)
				height: 14px
				width: 1px
				@screen lg
					right: r(-18px)
				@media screen and ( max-width: 1024px)
					font-size: 14px
			&.active
				a,span
					@apply text-primary-700
			&:first-child
				@apply pl-0
				span,a
					font-size: 0 !important
				&:after
					right: r(-22px)
				&:before
					content: '\f015'
					font-family: 'Font Awesome 6 Pro'
					@apply text-grey-500 block pointer-events-none z-10 font-light
					font-size: 16px
					height: 16px
					width: 16px
			&:last-child
				&:after
					@apply hidden
				a
					@apply text-grey-666
			a,span
				@apply text-grey-500 font-normal 
				font-size: 14px