.searchresults
	main

		@apply bg-white
.input-group-text
	@apply hidden
.search-page
	padding: 70px 0
	h1
		letter-spacing: -2px
		margin-bottom: 20px
		font-size: 45px
		line-height: 55px
		font-weight: 500
		color: #333333
		@media (max-width: 992px)
			font-size: 24px
			line-height: 30px
		&.center
			text-align: center
	.btn-reindex
		display: none
	.searchcontrols
		.form-inline
			position: relative
			&:before
				position: absolute
				content: "\f002"
				font-family: 'Font Awesome 6 Pro'
				top: 0
				right: 10px
				z-index: 1
				font-size: 24px
				color: #4285f4
				width: 44px
				height: 44px
				display: flex
				align-items: center
				justify-content: center
				pointer-events: none
		.frm-btn
			position: absolute
			top: 0
			right: 10px
			background-color: rgba(0,0,0,0)
			z-index: 2
	input[type="text"]
		width: 100%
		height: 100%
		padding-left: 20px
		padding-right: 54px
		border: 1px solid #dfe1e5
		background-color: #fff
		color: #333
		font-size: .875rem
		font-weight: 400
		height: 44px
		border-radius: 22px
		box-shadow: 0 0 0 0px rgba(0,0,0,0)
		transition: .25s cubic-bezier(.4,0,.2,1)
		outline: none
		&:focus
			box-shadow: 0 1px 6px 0 rgba(32,33,36,0.28)
			border-color: rgba(223,225,229,0)
	input[type="submit"]
		border-radius: 2px
		user-select: none
		background-color: rgba(0,0,0,0)
		border: 0
		box-shadow: none
		cursor: pointer
		text-align: center
		display: flex
		align-items: center
		justify-content: center
		height: 40px
		outline: none
		width: 44px
		height: 44px
		border-radius: 50%
		font-size: 0
	.text-danger
		margin-top: 20px
		font-size: 15px
		color: #333
		font-style: italic
		font-weight: 600
	.searchresultsummary
		margin-bottom: 20px
	.searchresults
		margin-top: 30px
		.modulepager
			&:first-child
				display: none
	.searchresultlist
		margin-bottom: 30px
	.searchresult
		margin-bottom: 30px
		box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28)
		padding: 16px
		border-radius: 8px
		&:last-child
			margin-bottom: 0
		h3
			font-size: 18px
			line-height: 1.33
			font-weight: 400
			margin-bottom: 10px
			a
				color: #1A0DAB
				text-decoration: none
				font-weight: 500
				// &:hover
				// 	text-decoration: underline
		.searchresultdesc
			color: #545454
			line-height: 1.54
			word-wrap: break-word
			font-size: small
			font-weight: 400
			margin-bottom: 10px
		.searchterm
			color: #6a6a6a
			font-weight: bold
			font-style: normal
			line-height: 1.54
			word-wrap: break-word
			font-size: small
		hr
			display: none !important
	.search-title
		@apply text-24 text-grey-333 font-bold mb-4 block uppercase
	.text-main
		@apply font-normal text-18 text-26 normal-case
	.searchresultsummary
		@apply text-16 text-grey-333 font-semibold
	.kw-desc
		@apply font-bold text-grey-333
.search-overlay
	.container
		max-width: 900px
		@apply p-0
	@media screen and ( max-width: 1200px)
		@apply w-full
	@screen xl
		top: 130px
		@apply bg-white opacity-0 pointer-events-none transition-all fixed w-full flex items-center justify-center left-0  py-15 z-40 mb-0
		@media (max-width: 1024px)
			height: 40vh
			top: 60px
		&.is-open
			@apply opacity-100 pointer-events-auto
			.searchbox
			.close
				@apply opacity-100

.search-wrap
	@media screen and ( max-width: 1200px)
		@apply w-full
.searchbox
	height: 36px
	@apply rounded overflow-hidden start-item text-clamp-16 shadow-lg
	@media screen and ( max-width: 1200px)
		@apply overflow-visible relative  
		background: white !important
		height: 45px
	
	input[type='text']
		@apply w-full h-full bg-transparent px-5
		@media screen and ( max-width: 1200px)
			@apply bg-white text-grey-333  rounded  font-semibold
		@screen xl
			@apply w-full
		&:placeholder
			@apply  opacity-70 text-clamp-14 text-grey-600
	button
		@apply center-item 
		@screen xl
			@apply bg-primary-600 w-[40px] h-[40px]  center-item
			em
				@apply text-white
		@media screen and ( max-width: 1200px)
			@apply bg-grey-800 w-[36px] h-[36px]  rounded-[4px] pr-0 mr-2
			min-width: 36px
		em
			@apply text-white text-clamp-16 leading-none
.error-message
	@apply font-bold text-center text-clamp-18 leading-tight
.suggestsearch
	z-index: 120
	@apply rounded-lg overflow-hidden w-full absolute-x bg-white p-4 top-full top-full
	@screen lg
		height: max-content
	.suggest-list
		height: 360px
		padding-right: 15px
		overflow-y: auto
		scrollbar-width: thin
		scrollbar-color: #464646 #EFEFEF
		&::-webkit-scrollbar
			background: #EFEFEF
			width: 3px
		&::-webkit-scrollbar-track
			-webkit-box-shadow: inset 0 0 6px #EFEFEF
			background-color: #EFEFEF
		&::-webkit-scrollbar-thumb
			background: linear-gradient(270deg, #CFA462 1.1%, #FBCB80 52.1%, #FFD087 100%)
		@screen lg
			height: 300px
		@media screen and ( max-width:1279.1px)
			height: 400px
		.product,.news
			@apply  start-item mb-2 pb-2
			border-bottom: 1px solid #f2f2f2
			@media screen and ( max-width: 576px)
				@apply justify-start
			.txt
				@apply ml-3
			.title
				@apply mb-2
				a
					@apply text-grey-333 font-semibold mb-0 text-14 transition
					display: -webkit-box
					-webkit-line-clamp: 3
					-webkit-box-orient: vertical
					overflow: hidden
					&:hover
						@apply text-red
			.price
				@apply text-red font-bold text-14
			.img
				@apply rounded-lg overflow-hidden 
				display: block
				height: 90px
				max-width: 90px
				min-width: 90px
				width: auto
			
				img
					width: 100%
					height: 100%
					object-fit: contain
		.news
			.img
				img
					object-fit: cover !important