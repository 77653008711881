footer
	background: linear-gradient(180deg, #0E538F 0%, #51AEFF 100%)
	.footer-top
		@apply pb-10
	.logo
		@apply mb-5
		a
			@apply start-item
			height: r(50px)
			width: auto
			@media screen and ( max-width: 1024px)
				@apply center-item
			img
				width: auto
				height: 100%
				object-fit: contain
	address
		ol
			@apply not-italic
		li
			@apply start-item text-clamp-18 font-normal
			align-items: flex-start
			*
				@apply text-clamp-18 font-normal
			&:not(:last-child)
				@apply mb-4
			em
				@apply mr-3 mt-1
	ul
		li
			&:not(:last-child)
				@apply mb-4
		a
			@apply text-clamp-18 font-normal
			&:hover
				@apply underline
.footer-bottom
	@apply py-5 border-t border-t-white border-opacity-20
	.container-fluid
		@media screen and ( max-width: 576px)
			@apply col-ver
.copyright
	@apply text-clamp-14 font-normal text-white
	*
		@apply text-clamp-14 font-normal text-white
	@media screen and ( max-width: 576px)
		@apply order-2 pt-4
.social-network
	@apply center-item
	.ModuleContent
		@apply center-item
	a
		@apply center-item bg-primary-00A8DF bg-opacity-20
		width: r(40px)
		height: r(40px)
		&:not(:last-child)
			@apply mr-[10px]
		&:hover
			em
				transform: translateY(-3px)
		em
			@apply text-white text-clamp-20 transition
			@screen xl
				@apply text-clamp-16