.subscribe
	@apply pt-5
	.subscribethanks 
		@apply mx-auto text-center
	.subscribefrm
		@apply relative flex items-center justify-center 
	input[type='text']
		@apply text-clamp-16  font-normal bg-white px-5 w-full text-grey-333
		height: 46px
		&::placeholder
			@apply text-clamp-16 
			color: #7A7A7A
	button
		height: 46px
		width: 80px
		@apply center-item ml-2 bg-green-400 border border-green-400 uppercase
		&:focus
			outline: none
		&:hover
			@apply bg-white text-green-400
