.home-news
	background: linear-gradient(180deg, #EFFBFF 0%, #F6FFF8 100%)
	.bg-wrap
		@apply rounded-4 overflow-hidden p-8 h-full
		box-shadow: 0px 2px 16px 0px #00000014
.news-side
	@apply start-item items-start py-4
	@screen xl
		@apply py-5 border-b border-b-grey-200
	&:first-child
		@apply pt-0
	.icon
		@apply flex items-start mr-5
		height: 32px
		width: auto
		img
			width: auto
			height: 100%
			object-fit: contain
	time
		@apply leading-none
.news-hor
	&:not(:last-child)
		@apply mb-5
	@media screen and ( min-width: 400px)
		@apply start-item 
	.img
		@apply rounded-2 overflow-hidden
		flex: 0 1 auto
		width: r(190px)
		min-width: r(190px)
		max-width: r(190px)
		@media screen and ( min-width: 400px)
			@apply mr-5
		a
			@apply center-item 
			@media screen and ( min-width: 400px)
				width: r(190px)
				min-width: r(190px)
				max-width: r(190px)
				height: r(105px)
			@media screen and ( max-width: 400px)
				+img-ratio(480,640)

			img
				width: 100%
				height: 100%
				object-fit: cover
	.txt
		flex: 0 1 auto
		@media screen and ( max-width: 400px)
			@apply pt-5
	time
		@apply mb-2
	.head-title
		a
			+line(2)