.popup-modal
	padding: 0
	border-radius: 10px
	@apply w-full
.carousel__button
	&.is-close
		top: 0 !important
		width: 50px
		height: 50px
		right: 0 !important
		svg
			@apply text-primary-700
.popup-modal-wrap
	overflow: hidden
.access-modal
	max-width: 640px
	padding: r(32px) !important
	@screen lg
		padding: r(36px) r(60px) !important
	.form-group
		&:first-child
			@apply mb-5
	.btn
		width: 200px
		@media screen and ( max-width: 576px)
			@apply w-full
			margin: 0 0 12px 0 !important
	.wrap-center
		@media screen and ( max-width: 576px)
			@apply col-ver
	.headline
		@apply block