.language-wrap
	.Module
		@apply h-full
.language-list
	.active
		a
			@apply text-primary-400
	li
		&:not(:last-child)
			@apply border-r border-r-primary-400
		@media screen and ( max-width: 1024px)
			&:last-child
				a
					@apply pr-0
	a
	
		&:hover
			@apply underline
