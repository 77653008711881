.home-partner
	.home-partner-swiper
	.swiper-wrapper
		transition-timing-function: linear

.partner-item
	.img
		a
			@apply center-item px-4
			height: r(100px)
			width: auto
			img
				width: 100%
				height: 100%
				object-fit: contain
				aspect-ratio: 96/192