header
	height: 150px
	@apply fixed top-0 left-0 right-0 w-full transition 
	z-index: 999
	background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)
	@media screen and ( max-width: 1200px)
		height: 60px
		@apply bg-white border-b border-b-green-400
	.container-fluid
		@apply transition
		@screen xl
			@apply  gap-wrap
			align-items: center
			height: 100px

body
	&::after
		content: ''
		@apply block absolute pointer-events-none left-0 bottom-0 z-10  transition w-full h-full bg-dark bg-opacity-40 opacity-0
	&.overlay-bg
		@apply overflow-hidden
		&::after
			@apply opacity-100
	@screen xl
		&.minimize
			header
				height: 100px
				@apply bg-white 
			.header-top
				height: 60px
			.nav-brand
				a
					height: 50px
			.search-overlay
				top: 100px
.Module-1340
	@apply w-full
	.ModuleContent
		@apply w-full
.nav-brand
	@apply z-20
	@media screen and ( min-width: 1200px)
		@apply absolute-x top-[30px]
	@media screen and ( max-width: 1200px)
		@apply absolute-center
	a
		display: flex
		height: 50px
		width: auto
		@apply transition
		@media screen and ( min-width: 1200px)
			height: 50px
		img,svg
			width: 100%
			height: 100%
			object-fit: contain
.site-menu-toggle
	@apply  center-item items-center mt-1 
.mobile-nav-wrap
	@apply fixed top-0 left-0 w-full h-full bg-primary-700 col-hor 
	z-index: 9999
	padding: r(30px)
	transform: translateX(-100%)
	transition: transform .8s cubic-bezier(.165,.84,.44,1)
	justify-content: space-between
	@media screen and ( min-width:768px )
		width: r(670px)
	@media screen and ( max-width: 768px)
		justify-content: flex-start
	@media screen and ( max-width: 1024px)
		@apply flex flex-col
		.Module-1340
			flex: 1
			overflow: auto
	&.is-open
		transform: none
	.mobile-top-nav
		@apply z-30 col-hor items-start
	.close-menu
		@apply start-item mb-5
		em,span
			@apply  text-white  leading-none
		em
			@apply text-clamp-32 font-black
		span
			@apply text-clamp-20 ml-3 font-semibold 
.nav-primary-menu
	@apply relative z-40 w-full
	@screen xl
		@apply  w-1/2
	@media screen and ( max-width: 768px)
		@apply pt-8
	.nav
		@apply col-hor  w-full
	.nav-link
		@apply z-10 start-item w-full
		a
			@apply relative z-20 text-white transition start-item font-normal text-clamp-18 w-fit
		.title
			@apply relative w-full
			em
				@apply absolute-y right-[15px] z-30 opacity-0 text-clamp-20 transition text-green-400
				@media screen and ( max-width: 1200px)
					@apply opacity-100
					transform: rotate(90deg)
				@screen xl
					@apply text-clamp-24 right-[30px]
		.title a,>a	
			padding: r(20px)  r(20px)  r(20px) 0
			background-image: linear-gradient(120deg, transparent 0%, transparent 50%, #00A8DF 50%)
			background-size: 253%
			transition: all 0.6s
			em
				@apply opacity-0
		.nav-sub
			@apply w-full pl-8 pt-3
			@media screen and ( max-width: 1200px)
				@apply hidden
			@screen xl
				@apply absolute top-0  left-full h-full z-30 opacity-0 pointer-events-none transition pl-6
				&::after
					content: ''
					@apply block absolute pointer-events-none left-0 bottom-0 z-10 bg-white opacity-20 transition w-[1px] h-full
			a
				@apply text-white text-clamp-16  py-3
				@screen xl
					@apply text-clamp-18 py-2
				&.active,&:hover
					@apply underline
		&.active,&:hover
			.title a,>a
				background-position: 100%
				padding-left: r(20px) 
				@apply text-white
		&:hover
			.title em
				@apply opacity-100
		&.active
			&:hover
				a
					@apply font-medium
	.drop-down
		@media screen and ( max-width: 1200px)
			@apply flex-col
		&:hover
			.nav-sub
				@apply opacity-100 pointer-events-auto
	.drop-down-lv-2
		&.toggle-dropdown
			.title-lv-2 
				em
					transform: rotate(90deg)
		.nav-sub-lv-2
			@apply hidden
		>.nav-sub-lv-2
			@apply px-4 py-2 rounded-2 bg-white bg-opacity-10
		.title-lv-2 
			@apply gap-wrap
			em
				@apply text-clamp-24 text-green-400 transition
	
.header-social-network
	@apply end-item mt-6
	@screen md
		@apply mt-8 
	@screen lg
		@apply mt-12
	@screen xl
		@apply mt-14 
	.ModuleContent
		@apply start-item
	a
		@apply  center-item  transition cursor-pointer bg-primary-00A8DF bg-opacity-20
		width: 40px
		height: 40px
		min-width: 40px
		&:not(:last-child)
			@apply mr-[10px]
		&:hover
			@apply bg-white
			em
				@apply text-primary-700
		em
			@apply text-white text-16 transition leading-none